import React, { useContext, useEffect } from 'react';
import { RicosContext, RicosViewerContext } from 'ricos-context';
import { FILE_UPLOAD_TYPE } from './viewer-loadable.js';
import classnames from 'classnames';
import { debounce } from 'lodash';
import { Node_Type } from 'ricos-schema';
import { isDefined } from 'wix-rich-content-common';
import Tooltip from 'wix-rich-content-common/libs/Tooltip';
import '@loadable/component';
import 'ricos-types';

var styles = {"fontElementMap":"poINv","file_upload_container":"XXMjZ","file_upload_error_container":"bjihl","file_upload_link":"vSvmz","width_three":"PWp4E","file_upload_text_container":"wxyc6","width_two":"-cLwd","file_upload_state":"Dg7FC","mobile_status_icon":"Vrkt3","file_upload_name_container":"I7Z7s","file_upload_name":"TfSkb","file_upload_extension":"eyJVx","width_one":"FFuS1","file_upload_type_icon":"GVUYv","file_loader_icon":"AnH-s","spin":"_4batC","file_upload_type":"chYnP","file_upload_text_error":"lxUFa","file_upload_icon_frame":"H97gh","file_upload_icon_background":"_78TqW"};

var ROOT = "fileUploadViewer";
var FILE_UPLOAD_NAME_CONTAINER = "fileUploadNameContainer";
var FILE_UPLOAD_NAME = "fileUploadName";
var FILE_UPLOAD_EXTENSION = "fileUploadExtension";

function _createForOfIteratorHelperLoose$b(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray$b(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray$b(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray$b(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray$b(r, a) : void 0; } }
function _arrayLikeToArray$b(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp$b = Object.defineProperty;
var __getOwnPropSymbols$b = Object.getOwnPropertySymbols;
var __hasOwnProp$b = Object.prototype.hasOwnProperty;
var __propIsEnum$b = Object.prototype.propertyIsEnumerable;
var __defNormalProp$b = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp$b(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues$b = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp$b.call(b, prop)) __defNormalProp$b(a, prop, b[prop]);
  if (__getOwnPropSymbols$b) {
    for (var _iterator = _createForOfIteratorHelperLoose$b(__getOwnPropSymbols$b(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum$b.call(b, prop)) __defNormalProp$b(a, prop, b[prop]);
    }
  }
  return a;
};
var DownloadIcon = function DownloadIcon(props) {
  return /* @__PURE__ */React.createElement("svg", __spreadValues$b({
    xmlns: "http://www.w3.org/2000/svg",
    width: "24",
    height: "24",
    viewBox: "0 0 24 24"
  }, props), /* @__PURE__ */React.createElement("path", {
    fill: "currentColor",
    fillRule: "evenodd",
    d: "M17.5 20c.276 0 .5.224.5.5s-.224.5-.5.5h-12c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h12zm-6-16c.276 0 .5.224.5.5v11.515l2.197-1.898c.2-.173.503-.151.677.049l.009.01.005.007c.17.21.144.517-.06.694l-2.978 2.574c-.048.042-.103.072-.16.092-.165.089-.375.075-.53-.051l-.01-.009-2.977-2.573c-.205-.177-.231-.485-.06-.694l.005-.007c.167-.205.47-.236.675-.069l.01.01L11 16.047V4.5c0-.276.224-.5.5-.5z"
  }));
};

function _createForOfIteratorHelperLoose$a(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray$a(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray$a(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray$a(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray$a(r, a) : void 0; } }
function _arrayLikeToArray$a(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp$a = Object.defineProperty;
var __getOwnPropSymbols$a = Object.getOwnPropertySymbols;
var __hasOwnProp$a = Object.prototype.hasOwnProperty;
var __propIsEnum$a = Object.prototype.propertyIsEnumerable;
var __defNormalProp$a = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp$a(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues$a = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp$a.call(b, prop)) __defNormalProp$a(a, prop, b[prop]);
  if (__getOwnPropSymbols$a) {
    for (var _iterator = _createForOfIteratorHelperLoose$a(__getOwnPropSymbols$a(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum$a.call(b, prop)) __defNormalProp$a(a, prop, b[prop]);
    }
  }
  return a;
};
var ErrorIcon = function ErrorIcon(props) {
  return /* @__PURE__ */React.createElement("svg", __spreadValues$a({
    xmlns: "http://www.w3.org/2000/svg",
    width: "28",
    height: "28",
    viewBox: "0 0 28 28"
  }, props), /* @__PURE__ */React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "#F64D43"
  }, /* @__PURE__ */React.createElement("path", {
    d: "M9.333 18.148C4.46 18.148.52 14.208.52 9.333.519 4.46 4.459.52 9.333.52c4.874 0 8.815 3.94 8.815 8.814 0 4.874-3.94 8.815-8.815 8.815zm0 0c4.874 0 8.815-3.94 8.815-8.815 0-4.874-3.94-8.814-8.815-8.814C4.46.519.52 4.459.52 9.333c0 4.874 3.94 8.815 8.814 8.815zM9.333 4.148L9.333 11.407M9.333 13.481L9.333 14.519",
    transform: "translate(4.667 4.667)"
  })));
};

var FileTypes = /* @__PURE__ */function (FileTypes2) {
  FileTypes2[FileTypes2["IMAGE"] = 1] = "IMAGE";
  FileTypes2[FileTypes2["VIDEO"] = 2] = "VIDEO";
  FileTypes2[FileTypes2["WORD"] = 3] = "WORD";
  FileTypes2[FileTypes2["EXCEL"] = 4] = "EXCEL";
  FileTypes2[FileTypes2["ARCHIVE"] = 5] = "ARCHIVE";
  FileTypes2[FileTypes2["PDF"] = 6] = "PDF";
  FileTypes2[FileTypes2["POWERPOINT"] = 7] = "POWERPOINT";
  FileTypes2[FileTypes2["AUDIO"] = 8] = "AUDIO";
  FileTypes2[FileTypes2["MISC"] = 9] = "MISC";
  return FileTypes2;
}(FileTypes || {});
var doc = new RegExp("^doc([a-z]*)$");
var excel = new RegExp("^xl([a-z]*)$");
var ppt = new RegExp("^ppt([a-z]*)$");
var images = ["jpg", "png", "gif", "jpeg", "jpe", "jfif", "bmp", "heic", "heif", "tfif", "tif", "webp"];
var videos = ["avi", "mpeg", "mpg", "mpe", "mp4", "mkv", "webm", "mov", "ogv", "vob", "m4v", "3gp", "divx", "xvid", "mxf", "wmv", "m1v", "flv"];
var audios = ["mp3", "pcm", "wav", "aiff", "aif", "aac", "ogg", "wma", "m4a", "flac"];
var pdf = ["pdf"];
var folder = ["zip", "rar", "tar", "gz", "gzip", "jar", "7z", "fgz", "webarchive"];
var extRegList = [{
  data: doc,
  fileType: 3 /* WORD */
}, {
  data: excel,
  fileType: 4 /* EXCEL */
}, {
  data: ppt,
  fileType: 7 /* POWERPOINT */
}];
var extList = [{
  data: images,
  fileType: 1 /* IMAGE */
}, {
  data: videos,
  fileType: 2 /* VIDEO */
}, {
  data: audios,
  fileType: 8 /* AUDIO */
}, {
  data: pdf,
  fileType: 6 /* PDF */
}, {
  data: folder,
  fileType: 5 /* ARCHIVE */
}];
function getFileTypeFromList(extension, checkList, extensionPredicate) {
  var retVal;
  checkList.some(function (_ref) {
    var data = _ref.data,
      fileType = _ref.fileType;
    if (extensionPredicate(data, extension)) {
      retVal = fileType;
      return true;
    }
    return false;
  });
  return retVal;
}
var fileExtensionToType = function fileExtensionToType(extension) {
  var fileType = extension && (getFileTypeFromList(extension, extRegList, function (regExp, extension2) {
    return regExp.test(extension2);
  }) || getFileTypeFromList(extension, extList, function (typeList, extension2) {
    return typeList.includes(extension2);
  }));
  return fileType || 9 /* MISC */;
};

function _createForOfIteratorHelperLoose$9(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray$9(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray$9(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray$9(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray$9(r, a) : void 0; } }
function _arrayLikeToArray$9(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp$9 = Object.defineProperty;
var __getOwnPropSymbols$9 = Object.getOwnPropertySymbols;
var __hasOwnProp$9 = Object.prototype.hasOwnProperty;
var __propIsEnum$9 = Object.prototype.propertyIsEnumerable;
var __defNormalProp$9 = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp$9(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues$9 = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp$9.call(b, prop)) __defNormalProp$9(a, prop, b[prop]);
  if (__getOwnPropSymbols$9) {
    for (var _iterator = _createForOfIteratorHelperLoose$9(__getOwnPropSymbols$9(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum$9.call(b, prop)) __defNormalProp$9(a, prop, b[prop]);
    }
  }
  return a;
};
var __objRest$7 = function __objRest(source, exclude) {
  var target = {};
  for (var prop in source) if (__hasOwnProp$9.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols$9) {
    for (var _iterator2 = _createForOfIteratorHelperLoose$9(__getOwnPropSymbols$9(source)), _step2; !(_step2 = _iterator2()).done;) {
      var prop = _step2.value;
      if (exclude.indexOf(prop) < 0 && __propIsEnum$9.call(source, prop)) target[prop] = source[prop];
    }
  }
  return target;
};
var docIcon = function docIcon(_a) {
  var _b = _a,
    styles = _b.styles,
    rest = __objRest$7(_b, ["styles"]);
  return /* @__PURE__ */React.createElement("svg", __spreadValues$9({
    xmlns: "http://www.w3.org/2000/svg",
    width: "40",
    height: "42",
    viewBox: "0 0 40 42"
  }, rest), /* @__PURE__ */React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, /* @__PURE__ */React.createElement("path", {
    className: styles.file_upload_icon_background,
    d: "M0 0H4823V3877H0z",
    transform: "translate(-1446 -3614)"
  }), /* @__PURE__ */React.createElement("g", null, /* @__PURE__ */React.createElement("g", {
    fill: "currentColor",
    className: styles.file_upload_icon_frame,
    strokeLinejoin: "bevel",
    strokeWidth: "1.003"
  }, /* @__PURE__ */React.createElement("path", {
    d: "M32.5 41.5L0.5 41.5 0.5 0.5 20 0.5 32.5 13.3z",
    transform: "translate(-1446 -3614) translate(1446 3614) translate(3)"
  }), /* @__PURE__ */React.createElement("path", {
    fillRule: "nonzero",
    d: "M19.2 13.4L32.1 13.3 31.5 12.7 20.2 1.1 19.6 0.5z",
    transform: "translate(-1446 -3614) translate(1446 3614) translate(3)"
  })), /* @__PURE__ */React.createElement("path", {
    d: "M0 0H40V42H0z",
    transform: "translate(-1446 -3614) translate(1446 3614)"
  }), /* @__PURE__ */React.createElement("path", {
    className: styles.file_upload_icon_background,
    fillRule: "nonzero",
    d: "M3.1 0.6L4.6 6.8 4.9 8.6 5.2 6.9 6.5 0.6 8.9 0.6 10.2 6.8 10.5 8.6 10.9 6.9 12.3 0.6 14.7 0.6 11.6 11.5 9.4 11.5 8 5.1 7.7 3 7.3 5.1 5.9 11.5 3.8 11.5 0.7 0.6z",
    transform: "translate(-1446 -3614) translate(1446 3614) translate(12 19)"
  }))));
};

function _createForOfIteratorHelperLoose$8(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray$8(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray$8(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray$8(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray$8(r, a) : void 0; } }
function _arrayLikeToArray$8(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp$8 = Object.defineProperty;
var __getOwnPropSymbols$8 = Object.getOwnPropertySymbols;
var __hasOwnProp$8 = Object.prototype.hasOwnProperty;
var __propIsEnum$8 = Object.prototype.propertyIsEnumerable;
var __defNormalProp$8 = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp$8(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues$8 = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp$8.call(b, prop)) __defNormalProp$8(a, prop, b[prop]);
  if (__getOwnPropSymbols$8) {
    for (var _iterator = _createForOfIteratorHelperLoose$8(__getOwnPropSymbols$8(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum$8.call(b, prop)) __defNormalProp$8(a, prop, b[prop]);
    }
  }
  return a;
};
var __objRest$6 = function __objRest(source, exclude) {
  var target = {};
  for (var prop in source) if (__hasOwnProp$8.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols$8) {
    for (var _iterator2 = _createForOfIteratorHelperLoose$8(__getOwnPropSymbols$8(source)), _step2; !(_step2 = _iterator2()).done;) {
      var prop = _step2.value;
      if (exclude.indexOf(prop) < 0 && __propIsEnum$8.call(source, prop)) target[prop] = source[prop];
    }
  }
  return target;
};
var folderIcon = function folderIcon(_a) {
  var _b = _a,
    styles = _b.styles,
    rest = __objRest$6(_b, ["styles"]);
  return /* @__PURE__ */React.createElement("svg", __spreadValues$8({
    xmlns: "http://www.w3.org/2000/svg",
    width: "40",
    height: "42",
    viewBox: "0 0 40 42"
  }, rest), /* @__PURE__ */React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, /* @__PURE__ */React.createElement("path", {
    className: styles.file_upload_icon_background,
    d: "M0 0H4823V3877H0z",
    transform: "translate(-1761 -3612)"
  }), /* @__PURE__ */React.createElement("path", {
    d: "M1761 3612H1801V3654H1761z",
    transform: "translate(-1761 -3612)"
  }), /* @__PURE__ */React.createElement("g", {
    className: styles.file_upload_icon_frame
  }, /* @__PURE__ */React.createElement("path", {
    fill: "currentColor",
    d: "M.716 29.29V1.726c0-.552.448-1 1-1h.003l13.104.039c.307 0 .597.143.786.385l3.812 4.888c.189.243.48.385.788.385h16.072c.552 0 1 .447 1 1V29.29h0H.716z",
    transform: "translate(-1761 -3612) translate(1762 3619)"
  }), /* @__PURE__ */React.createElement("path", {
    d: "M0.656 6.431L20.482 6.431",
    transform: "translate(-1761 -3612) translate(1762 3619)"
  }))));
};

function _createForOfIteratorHelperLoose$7(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray$7(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray$7(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray$7(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray$7(r, a) : void 0; } }
function _arrayLikeToArray$7(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp$7 = Object.defineProperty;
var __getOwnPropSymbols$7 = Object.getOwnPropertySymbols;
var __hasOwnProp$7 = Object.prototype.hasOwnProperty;
var __propIsEnum$7 = Object.prototype.propertyIsEnumerable;
var __defNormalProp$7 = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp$7(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues$7 = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp$7.call(b, prop)) __defNormalProp$7(a, prop, b[prop]);
  if (__getOwnPropSymbols$7) {
    for (var _iterator = _createForOfIteratorHelperLoose$7(__getOwnPropSymbols$7(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum$7.call(b, prop)) __defNormalProp$7(a, prop, b[prop]);
    }
  }
  return a;
};
var __objRest$5 = function __objRest(source, exclude) {
  var target = {};
  for (var prop in source) if (__hasOwnProp$7.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols$7) {
    for (var _iterator2 = _createForOfIteratorHelperLoose$7(__getOwnPropSymbols$7(source)), _step2; !(_step2 = _iterator2()).done;) {
      var prop = _step2.value;
      if (exclude.indexOf(prop) < 0 && __propIsEnum$7.call(source, prop)) target[prop] = source[prop];
    }
  }
  return target;
};
var imageIcon = function imageIcon(_a) {
  var _b = _a,
    styles = _b.styles,
    rest = __objRest$5(_b, ["styles"]);
  return /* @__PURE__ */React.createElement("svg", __spreadValues$7({
    xmlns: "http://www.w3.org/2000/svg",
    width: "40",
    height: "42",
    viewBox: "0 0 40 42"
  }, rest), /* @__PURE__ */React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, /* @__PURE__ */React.createElement("path", {
    fill: "#FFF",
    fillOpacity: "0.0",
    d: "M0 0H4823V3877H0z",
    transform: "translate(-1671 -3612)"
  }), /* @__PURE__ */React.createElement("path", {
    d: "M1671 3612H1711V3654H1671z",
    transform: "translate(-1671 -3612)"
  }), /* @__PURE__ */React.createElement("g", null, /* @__PURE__ */React.createElement("g", {
    className: styles.file_upload_icon_frame,
    strokeLinejoin: "bevel",
    strokeWidth: "1.003"
  }, /* @__PURE__ */React.createElement("path", {
    fill: "currentColor",
    d: "M32.5 41.5L0.5 41.5 0.5 0.5 20 0.5 32.5 13.3z",
    transform: "translate(-1671 -3612) translate(1674 3613)"
  }), /* @__PURE__ */React.createElement("path", {
    d: "M19.6 13.4L32.5 13.3 31.9 12.7 20.6 1.1 20 0.5z",
    transform: "translate(-1671 -3612) translate(1674 3613)"
  })), /* @__PURE__ */React.createElement("path", {
    className: styles.file_upload_icon_background,
    d: "M13.6 0.9L7.9 8.3 4.9 4.7 0.5 9.9 6.7 9.9 9.2 9.9 20.5 9.9z",
    transform: "translate(-1671 -3612) translate(1674 3613) translate(6 21)"
  }))));
};

function _createForOfIteratorHelperLoose$6(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray$6(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray$6(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray$6(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray$6(r, a) : void 0; } }
function _arrayLikeToArray$6(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp$6 = Object.defineProperty;
var __getOwnPropSymbols$6 = Object.getOwnPropertySymbols;
var __hasOwnProp$6 = Object.prototype.hasOwnProperty;
var __propIsEnum$6 = Object.prototype.propertyIsEnumerable;
var __defNormalProp$6 = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp$6(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues$6 = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp$6.call(b, prop)) __defNormalProp$6(a, prop, b[prop]);
  if (__getOwnPropSymbols$6) {
    for (var _iterator = _createForOfIteratorHelperLoose$6(__getOwnPropSymbols$6(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum$6.call(b, prop)) __defNormalProp$6(a, prop, b[prop]);
    }
  }
  return a;
};
var __objRest$4 = function __objRest(source, exclude) {
  var target = {};
  for (var prop in source) if (__hasOwnProp$6.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols$6) {
    for (var _iterator2 = _createForOfIteratorHelperLoose$6(__getOwnPropSymbols$6(source)), _step2; !(_step2 = _iterator2()).done;) {
      var prop = _step2.value;
      if (exclude.indexOf(prop) < 0 && __propIsEnum$6.call(source, prop)) target[prop] = source[prop];
    }
  }
  return target;
};
var otherIcon = function otherIcon(_a) {
  var _b = _a,
    styles = _b.styles,
    rest = __objRest$4(_b, ["styles"]);
  return /* @__PURE__ */React.createElement("svg", __spreadValues$6({
    xmlns: "http://www.w3.org/2000/svg",
    width: "40",
    height: "42",
    viewBox: "0 0 40 42"
  }, rest), /* @__PURE__ */React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, /* @__PURE__ */React.createElement("path", {
    className: styles.file_upload_icon_background,
    d: "M0 0H4823V3877H0z",
    transform: "translate(-1717 -3612)"
  }), /* @__PURE__ */React.createElement("path", {
    d: "M1717 3612H1757V3654H1717z",
    transform: "translate(-1717 -3612)"
  }), /* @__PURE__ */React.createElement("g", null, /* @__PURE__ */React.createElement("path", {
    className: styles.file_upload_icon_background,
    d: "M13.6 0.9L7.9 8.3 4.9 4.7 0.5 9.9 6.7 9.9 9.2 9.9 20.5 9.9z",
    transform: "translate(-1717 -3612) translate(1720 3613) translate(6 21)"
  }), /* @__PURE__ */React.createElement("path", {
    className: styles.file_upload_icon_background,
    fillRule: "nonzero",
    d: "M3.3 11.5L0.6 11.5 4.2 5.9 0.8 0.6 3.5 0.6 5.5 4.1 7.5 0.6 10.2 0.6 6.8 5.9 10.4 11.5 7.6 11.5 5.5 7.8z",
    transform: "translate(-1717 -3612) translate(1720 3613) translate(11 19)"
  }), /* @__PURE__ */React.createElement("path", {
    className: styles.file_upload_icon_background,
    fillRule: "nonzero",
    d: "M18.4 23.2c0-.6-.2-1-.5-1.2-.3-.2-.7-.4-1.3-.4h-2.1v3.2h2.1c.5 0 1-.1 1.3-.4.4-.3.5-.7.5-1.2zm2.3-.1c0 1.3-.3 2.2-1 2.8-.7.5-1.6.8-2.8.8h-2.3v4h-2.3v-11h4.8c1.1 0 2 .3 2.6.9.7.5 1 1.4 1 2.5z",
    transform: "translate(-1717 -3612) translate(1720 3613)"
  }), /* @__PURE__ */React.createElement("path", {
    className: styles.file_upload_icon_background,
    d: "M10.3 5.8L0.2 10.9 0.2 0.7z",
    transform: "translate(-1717 -3612) translate(1720 3613) translate(12 19)"
  }), /* @__PURE__ */React.createElement("g", {
    className: styles.file_upload_icon_frame,
    strokeLinejoin: "bevel",
    strokeWidth: "1.003"
  }, /* @__PURE__ */React.createElement("path", {
    fill: "currentColor",
    d: "M32.5 41.5L0.5 41.5 0.5 0.5 20 0.5 32.5 13.4z",
    transform: "translate(-1717 -3612) translate(1720 3613)"
  }), /* @__PURE__ */React.createElement("path", {
    d: "M19 13.4L31.9 13.4 31.3 12.7 20 1.2 19.4 0.5z",
    transform: "translate(-1717 -3612) translate(1720 3613)"
  })), /* @__PURE__ */React.createElement("g", {
    className: styles.file_upload_icon_background
  }, /* @__PURE__ */React.createElement("path", {
    className: styles.file_upload_icon_background,
    d: "M.5 4H10.5V5H.5zM.5 0H10.5V1H.5zM5.5 12L10.5 8 .5 8z",
    transform: "translate(-1717 -3612) translate(1720 3613) translate(10.963 19.09)"
  })))));
};

function _createForOfIteratorHelperLoose$5(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray$5(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray$5(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray$5(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray$5(r, a) : void 0; } }
function _arrayLikeToArray$5(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp$5 = Object.defineProperty;
var __getOwnPropSymbols$5 = Object.getOwnPropertySymbols;
var __hasOwnProp$5 = Object.prototype.hasOwnProperty;
var __propIsEnum$5 = Object.prototype.propertyIsEnumerable;
var __defNormalProp$5 = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp$5(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues$5 = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp$5.call(b, prop)) __defNormalProp$5(a, prop, b[prop]);
  if (__getOwnPropSymbols$5) {
    for (var _iterator = _createForOfIteratorHelperLoose$5(__getOwnPropSymbols$5(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum$5.call(b, prop)) __defNormalProp$5(a, prop, b[prop]);
    }
  }
  return a;
};
var __objRest$3 = function __objRest(source, exclude) {
  var target = {};
  for (var prop in source) if (__hasOwnProp$5.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols$5) {
    for (var _iterator2 = _createForOfIteratorHelperLoose$5(__getOwnPropSymbols$5(source)), _step2; !(_step2 = _iterator2()).done;) {
      var prop = _step2.value;
      if (exclude.indexOf(prop) < 0 && __propIsEnum$5.call(source, prop)) target[prop] = source[prop];
    }
  }
  return target;
};
var pdfIcon = function pdfIcon(_a) {
  var _b = _a,
    styles = _b.styles,
    rest = __objRest$3(_b, ["styles"]);
  return /* @__PURE__ */React.createElement("svg", __spreadValues$5({
    xmlns: "http://www.w3.org/2000/svg",
    width: "40",
    height: "42",
    viewBox: "0 0 40 42"
  }, rest), /* @__PURE__ */React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, /* @__PURE__ */React.createElement("path", {
    fill: "#FFF",
    fillOpacity: "0.0",
    d: "M0 0H4823V3877H0z",
    transform: "translate(-1579 -3613)"
  }), /* @__PURE__ */React.createElement("g", null, /* @__PURE__ */React.createElement("g", {
    className: styles.file_upload_icon_frame,
    strokeLinejoin: "bevel",
    strokeWidth: "1.003"
  }, /* @__PURE__ */React.createElement("path", {
    fill: "currentColor",
    d: "M32.5 41.5L0.5 41.5 0.5 0.5 20 0.5 32.5 13.3z",
    transform: "translate(-1579 -3613) translate(1582 3613)"
  }), /* @__PURE__ */React.createElement("path", {
    d: "M19.6 13.4L32.5 13.3 31.9 12.7 20.6 1.1 20 0.5z",
    transform: "translate(-1579 -3613) translate(1582 3613)"
  })), /* @__PURE__ */React.createElement("path", {
    className: styles.file_upload_icon_background,
    d: "M22.982 27.818s0 .1 0 0c0 .1-.2.2-.6.2h-.3c-.4-.1-1-.4-1.6-.9 1.7.1 2.3.5 2.5.7zm-5-1.7c-.6.1-1.4.2-2.4.4.4-.8.7-1.5 1-2.2.1.1.2.3.3.4.4.4.8.9 1.1 1.4zm-1.9-6.9c.1-.2.2-.3.2-.3.1.1.3.4.2 2 0 .3-.1.6-.2 1-.3-1-.4-2-.2-2.7zm-4.5 11.3c-.2.1-.3.2-.5.2 0-.1.1-.6.8-1.3.4-.3.8-.7 1.3-.9-.6 1-1.2 1.8-1.6 2zm7.8-4.5c-.6-.6-1.1-1.4-1.7-2l-.6-.9c.3-.9.5-1.6.5-2.2.1-1.4-.1-2.3-.5-2.8-.3-.3-.7-.4-1.1-.2-.3.1-.7.3-.9 1-.4 1-.2 2.9.7 4.4-.4 1.1-1 2.4-1.6 3.6-1.3.4-2.3 1-3 1.7-.9.9-1.3 1.8-1 2.5.1.5.5.7 1 .7.3 0 .7-.1 1.1-.4.9-.6 2-2.5 2.7-3.6 1.2-.4 2.5-.6 3.1-.6.3 0 .6-.1.9-.1 1.1 1.2 2.1 1.8 2.9 2h.5c.7 0 1.3-.3 1.5-.7.2-.4.2-.8 0-1.1-.5-.8-1.9-1.3-4-1.3h-.5z",
    transform: "translate(-1579 -3613) translate(1582 3613)"
  }))));
};

function _createForOfIteratorHelperLoose$4(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray$4(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray$4(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray$4(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray$4(r, a) : void 0; } }
function _arrayLikeToArray$4(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp$4 = Object.defineProperty;
var __getOwnPropSymbols$4 = Object.getOwnPropertySymbols;
var __hasOwnProp$4 = Object.prototype.hasOwnProperty;
var __propIsEnum$4 = Object.prototype.propertyIsEnumerable;
var __defNormalProp$4 = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp$4(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues$4 = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp$4.call(b, prop)) __defNormalProp$4(a, prop, b[prop]);
  if (__getOwnPropSymbols$4) {
    for (var _iterator = _createForOfIteratorHelperLoose$4(__getOwnPropSymbols$4(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum$4.call(b, prop)) __defNormalProp$4(a, prop, b[prop]);
    }
  }
  return a;
};
var __objRest$2 = function __objRest(source, exclude) {
  var target = {};
  for (var prop in source) if (__hasOwnProp$4.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols$4) {
    for (var _iterator2 = _createForOfIteratorHelperLoose$4(__getOwnPropSymbols$4(source)), _step2; !(_step2 = _iterator2()).done;) {
      var prop = _step2.value;
      if (exclude.indexOf(prop) < 0 && __propIsEnum$4.call(source, prop)) target[prop] = source[prop];
    }
  }
  return target;
};
var pptIcon = function pptIcon(_a) {
  var _b = _a,
    styles = _b.styles,
    rest = __objRest$2(_b, ["styles"]);
  return /* @__PURE__ */React.createElement("svg", __spreadValues$4({
    xmlns: "http://www.w3.org/2000/svg",
    width: "40",
    height: "42",
    viewBox: "0 0 40 42"
  }, rest), /* @__PURE__ */React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, /* @__PURE__ */React.createElement("path", {
    className: styles.file_upload_icon_background,
    d: "M0 0H4823V3877H0z",
    transform: "translate(-1535 -3613)"
  }), /* @__PURE__ */React.createElement("path", {
    d: "M1535 3613H1575V3655H1535z",
    transform: "translate(-1535 -3613)"
  }), /* @__PURE__ */React.createElement("g", null, /* @__PURE__ */React.createElement("path", {
    className: styles.file_upload_icon_background,
    d: "M13.6 0.9L7.9 8.3 4.9 4.7 0.5 9.9 6.7 9.9 9.2 9.9 20.5 9.9z",
    transform: "translate(-1535 -3613) translate(1535 3613) translate(9 21)"
  }), /* @__PURE__ */React.createElement("path", {
    className: styles.file_upload_icon_background,
    fillRule: "nonzero",
    d: "M3.3 11.5L0.6 11.5 4.2 5.9 0.8 0.6 3.5 0.6 5.5 4.1 7.5 0.6 10.2 0.6 6.8 5.9 10.4 11.5 7.6 11.5 5.5 7.8z",
    transform: "translate(-1535 -3613) translate(1535 3613) translate(14 19)"
  }), /* @__PURE__ */React.createElement("g", {
    className: styles.file_upload_icon_frame,
    strokeLinejoin: "bevel",
    strokeWidth: "1.003"
  }, /* @__PURE__ */React.createElement("path", {
    fill: "currentColor",
    d: "M35.5 41.5L3.5 41.5 3.5 0.5 23 0.5 35.5 13.3z",
    transform: "translate(-1535 -3613) translate(1535 3613)"
  }), /* @__PURE__ */React.createElement("path", {
    d: "M22.6 13.4L35.5 13.3 34.9 12.7 23.6 1.1 23 0.5z",
    transform: "translate(-1535 -3613) translate(1535 3613)"
  })), /* @__PURE__ */React.createElement("path", {
    className: styles.file_upload_icon_background,
    fillRule: "nonzero",
    d: "M21.4 23.2c0-.6-.2-1-.5-1.2-.3-.2-.7-.4-1.3-.4h-2.1v3.2h2.1c.5 0 1-.1 1.3-.4.4-.3.5-.7.5-1.2zm2.3-.1c0 1.3-.3 2.2-1 2.8-.7.5-1.6.8-2.8.8h-2.3v4h-2.3v-11h4.8c1.1 0 2 .3 2.6.9.7.5 1 1.4 1 2.5z",
    transform: "translate(-1535 -3613) translate(1535 3613)"
  }))));
};

function _createForOfIteratorHelperLoose$3(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray$3(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray$3(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray$3(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray$3(r, a) : void 0; } }
function _arrayLikeToArray$3(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp$3 = Object.defineProperty;
var __getOwnPropSymbols$3 = Object.getOwnPropertySymbols;
var __hasOwnProp$3 = Object.prototype.hasOwnProperty;
var __propIsEnum$3 = Object.prototype.propertyIsEnumerable;
var __defNormalProp$3 = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp$3(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues$3 = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp$3.call(b, prop)) __defNormalProp$3(a, prop, b[prop]);
  if (__getOwnPropSymbols$3) {
    for (var _iterator = _createForOfIteratorHelperLoose$3(__getOwnPropSymbols$3(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum$3.call(b, prop)) __defNormalProp$3(a, prop, b[prop]);
    }
  }
  return a;
};
var __objRest$1 = function __objRest(source, exclude) {
  var target = {};
  for (var prop in source) if (__hasOwnProp$3.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols$3) {
    for (var _iterator2 = _createForOfIteratorHelperLoose$3(__getOwnPropSymbols$3(source)), _step2; !(_step2 = _iterator2()).done;) {
      var prop = _step2.value;
      if (exclude.indexOf(prop) < 0 && __propIsEnum$3.call(source, prop)) target[prop] = source[prop];
    }
  }
  return target;
};
var vidAudIcon = function vidAudIcon(_a) {
  var _b = _a,
    styles = _b.styles,
    rest = __objRest$1(_b, ["styles"]);
  return /* @__PURE__ */React.createElement("svg", __spreadValues$3({
    xmlns: "http://www.w3.org/2000/svg",
    width: "40",
    height: "42",
    viewBox: "0 0 40 42"
  }, rest), /* @__PURE__ */React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, /* @__PURE__ */React.createElement("path", {
    fill: "#FFF",
    fillOpacity: "0.0",
    d: "M0 0H4823V3877H0z",
    transform: "translate(-1625 -3613)"
  }), /* @__PURE__ */React.createElement("path", {
    d: "M1625 3612H1665V3654H1625z",
    transform: "translate(-1625 -3613)"
  }), /* @__PURE__ */React.createElement("g", null, /* @__PURE__ */React.createElement("path", {
    fill: "#FFF",
    d: "M13.6 0.9L7.9 8.3 4.9 4.7 0.5 9.9 6.7 9.9 9.2 9.9 20.5 9.9z",
    transform: "translate(-1625 -3613) translate(1628 3613) translate(6 21)"
  }), /* @__PURE__ */React.createElement("path", {
    fill: "#FFF",
    fillRule: "nonzero",
    d: "M3.3 11.5L0.6 11.5 4.2 5.9 0.8 0.6 3.5 0.6 5.5 4.1 7.5 0.6 10.2 0.6 6.8 5.9 10.4 11.5 7.6 11.5 5.5 7.8z",
    transform: "translate(-1625 -3613) translate(1628 3613) translate(11 19)"
  }), /* @__PURE__ */React.createElement("path", {
    fill: "#FFF",
    fillRule: "nonzero",
    d: "M18.4 23.2c0-.6-.2-1-.5-1.2-.3-.2-.7-.4-1.3-.4h-2.1v3.2h2.1c.5 0 1-.1 1.3-.4.4-.3.5-.7.5-1.2zm2.3-.1c0 1.3-.3 2.2-1 2.8-.7.5-1.6.8-2.8.8h-2.3v4h-2.3v-11h4.8c1.1 0 2 .3 2.6.9.7.5 1 1.4 1 2.5z",
    transform: "translate(-1625 -3613) translate(1628 3613)"
  }), /* @__PURE__ */React.createElement("g", {
    className: styles.file_upload_icon_frame,
    strokeLinejoin: "bevel",
    strokeWidth: "1.003"
  }, /* @__PURE__ */React.createElement("path", {
    fill: "currentColor",
    d: "M32.5 41.5L0.5 41.5 0.5 0.5 20 0.5 32.5 13.3z",
    transform: "translate(-1625 -3613) translate(1628 3613)"
  }), /* @__PURE__ */React.createElement("path", {
    d: "M19 13.4L31.9 13.3 31.3 12.7 20 1.1 19.4 0.5z",
    transform: "translate(-1625 -3613) translate(1628 3613)"
  })), /* @__PURE__ */React.createElement("path", {
    className: styles.file_upload_icon_background,
    d: "M10.3 5.8L0.2 10.9 0.2 0.7z",
    transform: "translate(-1625 -3613) translate(1628 3613) translate(12 19)"
  }))));
};

function _createForOfIteratorHelperLoose$2(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray$2(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray$2(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray$2(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray$2(r, a) : void 0; } }
function _arrayLikeToArray$2(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp$2 = Object.defineProperty;
var __getOwnPropSymbols$2 = Object.getOwnPropertySymbols;
var __hasOwnProp$2 = Object.prototype.hasOwnProperty;
var __propIsEnum$2 = Object.prototype.propertyIsEnumerable;
var __defNormalProp$2 = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp$2(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues$2 = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp$2.call(b, prop)) __defNormalProp$2(a, prop, b[prop]);
  if (__getOwnPropSymbols$2) {
    for (var _iterator = _createForOfIteratorHelperLoose$2(__getOwnPropSymbols$2(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum$2.call(b, prop)) __defNormalProp$2(a, prop, b[prop]);
    }
  }
  return a;
};
var __objRest = function __objRest(source, exclude) {
  var target = {};
  for (var prop in source) if (__hasOwnProp$2.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols$2) {
    for (var _iterator2 = _createForOfIteratorHelperLoose$2(__getOwnPropSymbols$2(source)), _step2; !(_step2 = _iterator2()).done;) {
      var prop = _step2.value;
      if (exclude.indexOf(prop) < 0 && __propIsEnum$2.call(source, prop)) target[prop] = source[prop];
    }
  }
  return target;
};
var xlsIcon = function xlsIcon(_a) {
  var _b = _a,
    styles = _b.styles,
    rest = __objRest(_b, ["styles"]);
  return /* @__PURE__ */React.createElement("svg", __spreadValues$2({
    xmlns: "http://www.w3.org/2000/svg",
    width: "40",
    height: "42",
    viewBox: "0 0 40 42"
  }, rest), /* @__PURE__ */React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, /* @__PURE__ */React.createElement("path", {
    className: styles.file_upload_icon_background,
    d: "M0 0H4823V3877H0z",
    transform: "translate(-1489 -3614)"
  }), /* @__PURE__ */React.createElement("path", {
    className: styles.file_upload_icon_background,
    d: "M1489 3613H1529V3655H1489z",
    transform: "translate(-1489 -3614)"
  }), /* @__PURE__ */React.createElement("g", null, /* @__PURE__ */React.createElement("path", {
    d: "M13.63 0.89L7.93 8.34 4.88 4.75 0.5 9.89 6.75 9.89 9.25 9.89 20.5 9.89z",
    transform: "translate(-1489 -3614) translate(1492 3613) translate(6 21)"
  }), /* @__PURE__ */React.createElement("g", {
    className: styles.file_upload_icon_frame,
    strokeLinejoin: "bevel"
  }, /* @__PURE__ */React.createElement("path", {
    fill: "currentColor",
    d: "M32.5 41.5L0.5 41.5 0.5 0.5 19.95 0.5 32.5 13.31z",
    transform: "translate(-1489 -3614) translate(1492 3613)"
  }), /* @__PURE__ */React.createElement("path", {
    d: "M19.59 13.36L32.5 13.31 31.86 12.68 20.59 1.14 19.95 0.5z",
    transform: "translate(-1489 -3614) translate(1492 3613)"
  })), /* @__PURE__ */React.createElement("path", {
    className: styles.file_upload_icon_background,
    fillRule: "nonzero",
    d: "M3.29 11.55L0.62 11.55 4.16 5.95 0.8 0.55 3.54 0.55 5.5 4.13 7.52 0.55 10.17 0.55 6.81 5.86 10.38 11.55 7.59 11.55 5.5 7.8z",
    transform: "translate(-1489 -3614) translate(1492 3613) translate(11 19)"
  }))));
};

var _iconMap;
var iconMap = (_iconMap = {}, _iconMap[FileTypes.IMAGE] = imageIcon, _iconMap[FileTypes.VIDEO] = vidAudIcon, _iconMap[FileTypes.AUDIO] = vidAudIcon, _iconMap[FileTypes.PDF] = pdfIcon, _iconMap[FileTypes.ARCHIVE] = folderIcon, _iconMap[FileTypes.WORD] = docIcon, _iconMap[FileTypes.EXCEL] = xlsIcon, _iconMap[FileTypes.POWERPOINT] = pptIcon, _iconMap[FileTypes.MISC] = otherIcon, _iconMap);
var getIcon = function getIcon(extension) {
  return iconMap[fileExtensionToType(extension)];
};

function _createForOfIteratorHelperLoose$1(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray$1(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray$1(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray$1(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray$1(r, a) : void 0; } }
function _arrayLikeToArray$1(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp$1 = Object.defineProperty;
var __getOwnPropSymbols$1 = Object.getOwnPropertySymbols;
var __hasOwnProp$1 = Object.prototype.hasOwnProperty;
var __propIsEnum$1 = Object.prototype.propertyIsEnumerable;
var __defNormalProp$1 = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp$1(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues$1 = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp$1.call(b, prop)) __defNormalProp$1(a, prop, b[prop]);
  if (__getOwnPropSymbols$1) {
    for (var _iterator = _createForOfIteratorHelperLoose$1(__getOwnPropSymbols$1(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum$1.call(b, prop)) __defNormalProp$1(a, prop, b[prop]);
    }
  }
  return a;
};
var LoaderIcon = function LoaderIcon(props) {
  return /* @__PURE__ */React.createElement("svg", __spreadValues$1({
    xmlns: "http://www.w3.org/2000/svg",
    width: "24",
    height: "24",
    viewBox: "0 0 24 24"
  }, props), /* @__PURE__ */React.createElement("defs", null, /* @__PURE__ */React.createElement("path", {
    id: "file-upload-loader-icon-path",
    d: "M14.064 5.948l1.518-.507A8 8 0 1 1 10.473.39l-.506 1.519a6.4 6.4 0 1 0 4.097 4.04z"
  })), /* @__PURE__ */React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    transform: "translate(4 4)"
  }, /* @__PURE__ */React.createElement("mask", {
    id: "file-upload-loader-mask",
    fill: "currentColor"
  }, /* @__PURE__ */React.createElement("use", {
    xlinkHref: "#file-upload-loader-icon-path"
  })), /* @__PURE__ */React.createElement("use", {
    fill: "currentColor",
    xlinkHref: "#file-upload-loader-icon-path"
  }), /* @__PURE__ */React.createElement("path", {
    fill: "currentColor",
    d: "M-4-4h24v24H-4z",
    mask: "url(#file-upload-loader-mask)"
  })));
};

function _createForOfIteratorHelperLoose(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) {
    for (var _iterator = _createForOfIteratorHelperLoose(__getOwnPropSymbols(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
    }
  }
  return a;
};
var ReadyIcon = function ReadyIcon(props) {
  return /* @__PURE__ */React.createElement("svg", __spreadValues({
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink",
    width: "24",
    height: "24",
    viewBox: "0 0 24 24"
  }, props), /* @__PURE__ */React.createElement("defs", null, /* @__PURE__ */React.createElement("filter", {
    id: "file_upload_ready_icon_a",
    width: "271.4%",
    height: "340%",
    x: "-85.7%",
    y: "-85.7%",
    filterUnits: "objectBoundingBox"
  }, /* @__PURE__ */React.createElement("feOffset", {
    dy: "4",
    "in": "SourceAlpha",
    result: "shadowOffsetOuter1"
  }), /* @__PURE__ */React.createElement("feGaussianBlur", {
    "in": "shadowOffsetOuter1",
    result: "shadowBlurOuter1",
    stdDeviation: "4"
  }), /* @__PURE__ */React.createElement("feColorMatrix", {
    "in": "shadowBlurOuter1",
    result: "shadowMatrixOuter1",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
  }), /* @__PURE__ */React.createElement("feOffset", {
    "in": "SourceAlpha",
    result: "shadowOffsetOuter2"
  }), /* @__PURE__ */React.createElement("feGaussianBlur", {
    "in": "shadowOffsetOuter2",
    result: "shadowBlurOuter2",
    stdDeviation: "2"
  }), /* @__PURE__ */React.createElement("feColorMatrix", {
    "in": "shadowBlurOuter2",
    result: "shadowMatrixOuter2",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
  }), /* @__PURE__ */React.createElement("feMerge", null, /* @__PURE__ */React.createElement("feMergeNode", {
    "in": "shadowMatrixOuter1"
  }), /* @__PURE__ */React.createElement("feMergeNode", {
    "in": "shadowMatrixOuter2"
  }))), /* @__PURE__ */React.createElement("path", {
    id: "file_upload_ready_icon_b",
    d: "M16.346 0.167L17.167 0.963 6.97 11.833 0.833 6.925 1.534 6.019 6.861 10.279z"
  })), /* @__PURE__ */React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    transform: "translate(3 6)"
  }, /* @__PURE__ */React.createElement("use", {
    fill: "#000",
    filter: "url(#file_upload_ready_icon_a)",
    xlinkHref: "#file_upload_ready_icon_b"
  }), /* @__PURE__ */React.createElement("use", {
    fill: "#008250",
    xlinkHref: "#file_upload_ready_icon_b"
  })));
};

var getNameWithoutType = function getNameWithoutType(fileName) {
  if (!fileName || !fileName.includes(".")) {
    return fileName;
  }
  var s = fileName.split(".");
  return s.slice(0, s.length - 1).join(".");
};
var resizeWidths = {
  first: 320,
  second: 140,
  third: 100
};
var FileViewer = function FileViewer(_ref) {
  var settings = _ref.settings,
    componentData = _ref.componentData,
    _ref$isLoading = _ref.isLoading,
    isLoading = _ref$isLoading === void 0 ? false : _ref$isLoading,
    tempDataPlaceHolder = _ref.tempDataPlaceHolder,
    helpers = _ref.helpers,
    nodeId = _ref.nodeId;
  var _useContext = useContext(RicosContext),
    isMobile = _useContext.isMobile,
    t = _useContext.t,
    tabIndex = _useContext.tabIndex;
  var downloaderRef = React.useRef(null);
  var _React$useState = React.useState(false),
    shouldDownloadOnRender = _React$useState[0],
    setShouldDownloadOnRender = _React$useState[1];
  var _React$useState2 = React.useState(null),
    fileUploadViewerRef = _React$useState2[0],
    setFileUploadViewerRef = _React$useState2[1];
  var _React$useState3 = React.useState(void 0),
    resolvedFileUrl = _React$useState3[0],
    setResolvedFileUrl = _React$useState3[1];
  var _React$useState4 = React.useState(false),
    resolvingUrl = _React$useState4[0],
    setResolvingUrl = _React$useState4[1];
  var _React$useState5 = React.useState(false),
    showReadyIcon = _React$useState5[0],
    setShowReadyIcon = _React$useState5[1];
  var _React$useState6 = React.useState(0);
    _React$useState6[0];
    var setCurrentWidth = _React$useState6[1];
  useEffect(function () {
    if (!fileUploadViewerRef || !(window == null ? void 0 : window.ResizeObserver)) {
      return;
    }
    var resizer = new ResizeObserver(debounce(function (entries) {
      setCurrentWidth(Math.round(entries[0].contentRect.width));
    }, 60));
    resizer.observe(fileUploadViewerRef);
    return function () {
      resizer.unobserve(fileUploadViewerRef);
    };
  }, [fileUploadViewerRef]);
  useEffect(function () {
    var _a;
    if (!shouldDownloadOnRender || !downloaderRef.current) {
      return;
    }
    (_a = downloaderRef.current) == null ? void 0 : _a.click();
    setShouldDownloadOnRender(false);
  }, [shouldDownloadOnRender, downloaderRef.current]);
  useEffect(function () {
    if (isLoading) {
      switchReadyIcon();
    }
  }, [isLoading]);
  var switchReadyIcon = function switchReadyIcon() {
    setShowReadyIcon(true);
    setTimeout(function () {
      return setShowReadyIcon(false);
    }, 2e3);
  };
  var getIsInResizeRange = function getIsInResizeRange(resizeWidth) {
    return (fileUploadViewerRef == null ? void 0 : fileUploadViewerRef.clientWidth) && fileUploadViewerRef.clientWidth < resizeWidth;
  };
  var renderContainerWithoutLink = function renderContainerWithoutLink() {
    var _classnames;
    var _ref2 = tempDataPlaceHolder ? tempDataPlaceHolder : componentData,
      name = _ref2.name,
      type = _ref2.type;
    return /* @__PURE__ */React.createElement("div", {
      className: classnames(styles.file_upload_link, (_classnames = {}, _classnames[styles.width_three] = getIsInResizeRange(resizeWidths.third), _classnames))
    }, isDefined(name) && isDefined(type) && renderViewerBody({
      name: name,
      type: type
    }));
  };
  var renderIcon = function renderIcon(Icon) {
    var showLoader = isLoading || resolvingUrl;
    var showFileIcon = (!showLoader && !showReadyIcon && isMobile || !isMobile) && !!Icon;
    if (showFileIcon) {
      var _classnames2;
      return /* @__PURE__ */React.createElement(Icon, {
        styles: styles,
        className: classnames(styles.file_upload_type_icon, (_classnames2 = {}, _classnames2[styles.width_three] = getIsInResizeRange(resizeWidths.third), _classnames2))
      });
    }
    return /* @__PURE__ */React.createElement("div", {
      className: isMobile ? styles.mobile_status_icon : styles.file_upload_state
    }, componentData.error ? /* @__PURE__ */React.createElement(ErrorIcon, null) : showLoader ? /* @__PURE__ */React.createElement(LoaderIcon, {
      className: styles.file_loader_icon
    }) : showReadyIcon ? /* @__PURE__ */React.createElement(ReadyIcon, null) : !getIsInResizeRange(resizeWidths.first) && /* @__PURE__ */React.createElement(DownloadIcon, null));
  };
  var getFileInfoString = function getFileInfoString(type) {
    var _a;
    if (componentData.error) {
      return {
        infoString: t("UploadFile_Error_Generic_Item"),
        infoStyle: styles.file_upload_text_error
      };
    }
    var translationKey = isLoading || resolvingUrl ? "UploadFile_Viewer_Loader" : "UploadFile_Viewer_Download";
    var fileType = (_a = type == null ? void 0 : type.toUpperCase()) != null ? _a : "";
    var formattedFileSize = getFormattedFileSize(componentData);
    var fileSizeSuffix = formattedFileSize ? " \u2022 " + formattedFileSize : "";
    return {
      infoString: "" + t(translationKey, {
        fileType: fileType
      }) + fileSizeSuffix,
      infoStyle: styles.file_upload_type
    };
  };
  var getFormattedFileSize = function getFormattedFileSize(componentData2) {
    var _a, _b;
    var sizeInKb = (_a = componentData2.sizeInKb) != null ? _a : tempDataPlaceHolder == null ? void 0 : tempDataPlaceHolder.sizeInKb;
    var size = (_b = componentData2.size) != null ? _b : tempDataPlaceHolder == null ? void 0 : tempDataPlaceHolder.size;
    if (sizeInKb && !isNaN(parseFloat(sizeInKb))) {
      return sizeInKbToString(parseFloat(sizeInKb));
    }
    if (size) {
      return sizeToString(size);
    }
    return void 0;
  };
  var sizeInKbToString = function sizeInKbToString(sizeInKb) {
    if (sizeInKb > 1e3) {
      return (sizeInKb / 1e3).toFixed(2) + "MB";
    }
    return Math.round(sizeInKb) + "KB";
  };
  var sizeToString = function sizeToString(size) {
    return size < 1e3 ? size + "B" : size < 1e6 ? Math.round(size / 1e3) + "KB" : (size / 1e6).toFixed(2) + "MB";
  };
  var renderViewerBody = function renderViewerBody(_ref3) {
    var _classnames3, _classnames4;
    var name = _ref3.name,
      type = _ref3.type;
    var nameWithoutType = getNameWithoutType(name);
    var Icon = getIcon(type);
    var _getFileInfoString = getFileInfoString(type),
      infoString = _getFileInfoString.infoString,
      infoStyle = _getFileInfoString.infoStyle;
    return /* @__PURE__ */React.createElement(React.Fragment, null, renderIcon(Icon), !isMobile && renderIcon(), !getIsInResizeRange(resizeWidths.third) && /* @__PURE__ */React.createElement("div", {
      className: classnames(styles.file_upload_text_container, (_classnames3 = {}, _classnames3[styles.width_two] = getIsInResizeRange(resizeWidths.second), _classnames3))
    }, /* @__PURE__ */React.createElement("div", {
      className: styles.file_upload_name_container,
      "data-hook": FILE_UPLOAD_NAME_CONTAINER
    }, !getIsInResizeRange(resizeWidths.second) && /* @__PURE__ */React.createElement("div", {
      className: styles.file_upload_name,
      "data-hook": FILE_UPLOAD_NAME
    }, nameWithoutType), type && /* @__PURE__ */React.createElement("div", {
      className: classnames(styles.file_upload_extension, (_classnames4 = {}, _classnames4[styles.width_one] = getIsInResizeRange(resizeWidths.first), _classnames4)),
      "data-hook": FILE_UPLOAD_EXTENSION
    }, "." + type)), /* @__PURE__ */React.createElement("div", {
      className: infoStyle
    }, infoString)));
  };
  var renderViewer = function renderViewer(fileUrl) {
    var _classnames5;
    var name = componentData.name,
      type = componentData.type,
      error = componentData.error;
    var downloadTarget = settings.downloadTarget;
    if (error || tempDataPlaceHolder) {
      return renderContainerWithoutLink();
    }
    return /* @__PURE__ */React.createElement("a", {
      "data-cancel-link": true,
      tabIndex: tabIndex,
      href: fileUrl,
      ref: downloaderRef,
      target: downloadTarget,
      className: classnames(styles.file_upload_link, (_classnames5 = {}, _classnames5[styles.width_three] = getIsInResizeRange(resizeWidths.third), _classnames5)),
      onClick: onFileClick,
      download: true
    }, isDefined(name) && isDefined(type) && renderViewerBody({
      name: name,
      type: type
    }));
  };
  var renderFileUrlResolver = function renderFileUrlResolver() {
    var _classnames6;
    var name = componentData.name,
      type = componentData.type,
      error = componentData.error;
    if (error) {
      return renderContainerWithoutLink();
    }
    var fileUrlResolver = function fileUrlResolver() {
      var _a, _b;
      if (resolvingUrl) {
        return;
      }
      setResolvingUrl(true);
      settings.resolveFileUrl({
        id: (_a = componentData == null ? void 0 : componentData.src) == null ? void 0 : _a.id,
        type: componentData.type,
        path: componentData.path,
        mimeType: componentData.mimeType,
        name: componentData.name,
        size: componentData.size,
        pdfSettings: componentData.pdfSettings,
        privacy: ((_b = componentData == null ? void 0 : componentData.src) == null ? void 0 : _b["private"]) ? "private" : "public"
      }).then(function (resolvedFileUrl2) {
        setResolvedFileUrl(resolvedFileUrl2);
        setResolvingUrl(false);
        setShouldDownloadOnRender(true);
        switchReadyIcon();
      });
    };
    var resolveIfEnter = function resolveIfEnter(e) {
      if (e.key === "Enter") {
        fileUrlResolver();
      }
    };
    return /* @__PURE__ */React.createElement("button", {
      onClick: fileUrlResolver,
      onKeyDown: resolveIfEnter,
      className: classnames(styles.file_upload_link, (_classnames6 = {}, _classnames6[styles.width_three] = getIsInResizeRange(resizeWidths.third), _classnames6))
    }, isDefined(name) && isDefined(type) && renderViewerBody({
      name: name,
      type: type
    }));
  };
  var onFileClick = function onFileClick() {
    var _a;
    if (componentData.name) {
      (_a = helpers == null ? void 0 : helpers.onViewerAction) == null ? void 0 : _a.call(helpers, FILE_UPLOAD_TYPE, "Click", componentData.name, nodeId, {
        type: Node_Type.FILE,
        fileData: componentData
      });
    }
  };
  var getViewer = function getViewer() {
    var _a;
    var error = componentData.error;
    if (error || tempDataPlaceHolder) {
      return renderContainerWithoutLink();
    }
    var fileUrl = ((_a = componentData.src) == null ? void 0 : _a.url) || resolvedFileUrl;
    return fileUrl ? renderViewer(fileUrl) : renderFileUrlResolver();
  };
  return /* @__PURE__ */React.createElement(Tooltip, {
    content: getIsInResizeRange(resizeWidths.first) && componentData.name,
    tooltipOffset: {
      y: 25,
      x: 0
    }
  }, /* @__PURE__ */React.createElement("div", {
    "data-hook": ROOT,
    ref: setFileUploadViewerRef,
    className: classnames(styles.file_upload_container, componentData.error && styles.file_upload_error_container)
  }, getViewer()));
};

var File = function File(_ref) {
  var node = _ref.node,
    nodeIndex = _ref.nodeIndex,
    NodeViewContainer = _ref.NodeViewContainer;
  var _useContext = useContext(RicosViewerContext),
    config = _useContext.config,
    helpers = _useContext.helpers;
  return /* @__PURE__ */React.createElement(NodeViewContainer, {
    key: node.id,
    node: node,
    nodeIndex: nodeIndex
  }, /* @__PURE__ */React.createElement(FileViewer, {
    nodeId: node.id,
    componentData: node.fileData,
    settings: config[FILE_UPLOAD_TYPE],
    helpers: helpers
  }));
};

export { File as default };

import 'wix-rich-content-plugin-file-upload/dist/loadable/viewer/es/styles.viewer.min.global.css'